@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
$newFont: 'Avenir';
.wrapper,
.wrapperCompact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 16px 18px;
  font-family: $newFont;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  gap: 12px;

  .iconWrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 18px;
  }

  .contentOutterWrapper {
    .contentWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .title {
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
        color: #262338;
      }

      .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #6E7191;
      }
    }

    .link {
      display: inline-flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #423DCF;
      text-decoration: none;
      align-items: center;
      gap: 8.5px;
      // transition: all 0.4s;
      span:hover {
        font-weight: 600;
      }

      // &:hover {
      //   gap: 10.5px;
      // }
    }
  }
}

.wrapperCompact {
  flex-direction: row;
  gap: 20px;
}
